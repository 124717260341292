<template>
  <van-swipe class="my-swipe" :autoplay="3000" indicator-color="white">
    <van-swipe-item>
      <van-image fit :src="url" />
    </van-swipe-item>
  </van-swipe>
</template>
<script>
export default {
  props:{
      url:''
  },
  data() {
    return {
    };
  }
};
</script>
<style lang="less" scoped>
.my-swipe .van-swipe-item {
  color: #fff;
  font-size: 20px;
  text-align: center;
   border-radius: 0.4rem;

}
</style>